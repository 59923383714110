import { Box, Container, Grid, Typography } from '@mui/material';
import shortCurlyWave from 'assets/images/shortCurlyWave.svg';
import uday from 'assets/images/uday.png';
import london from 'assets/images/london.png';
import deutsche from 'assets/images/deutsche.png';
import waveStraightShort from 'assets/images/waveStraightShort.png';

export const InvestorOne = () => {
  return (
    <Box position="relative" sx={{ overflow: 'hidden' }}>
      <Box component="section" position="relative">
        <Container
          disableGutters
          sx={{
            position: 'relative',
            // height: 620,
            display: 'flex',
            flexDirection: 'column',
            zIndex: 100,
            bgcolor: '#fff',
            height: {
              xs: '30vh',
              md: 620,
            },
          }}
        >
          <Grid
            container
            sx={{
              display: {
                xs: 'flex',
                md: 'none',
              },
            }}
          >
            <Grid item xs={6}></Grid>
            <Grid
              item
              xs={6}
              position="relative"
              top="2vh"
              // justifyContent="flex-start"
              textAlign="left"
            >
              <Typography
                fontWeight={700}
                fontSize={18}
                color="rgba(0, 0, 0, 0.87)"
              >
                Uday Kumar BS
              </Typography>
              {/* <Typography
                fontSize={12}
                color="#C1C7D0"
                component="span"
                lineHeight="110px"
                letterSpacing="0.01"
                position="relative"
                top={65}
              >
                “
              </Typography> */}
              <Typography color="rgba(0, 0, 0, 0.54)" fontSize={8}>
                POD is first of its kind started in India enabling retail
                investors to private equity opportunities which was earlier
                limited to HNIs & VCs.
              </Typography>
              <Typography
                mt={1.5}
                pr="15%"
                fontWeight={400}
                fontSize={9}
                color="rgba(0, 0, 0, 0.87)"
              >
                Angel Investor & Trader Ex - London Stock Exchange Deutche Bank
              </Typography>
              <Grid container pr="8px">
                <Grid item xs={6}>
                  <img src={london} alt="london logo" />
                </Grid>
                <Grid item xs={6}>
                  <img src={deutsche} alt="deutsche logo" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            display="flex"
            flexGrow={1}
            sx={{
              display: {
                xs: 'none',
                md: 'inherit',
              },
            }}
          >
            <Grid item md={6}></Grid>
            <Grid item md={6} pr={20} pt={1} textAlign="start">
              <Typography
                fontSize={94}
                color="#C1C7D0"
                component="span"
                lineHeight="110px"
                letterSpacing="0.01"
                position="relative"
                top={65}
              >
                “
              </Typography>
              <Typography color="rgba(0, 0, 0, 0.54)" fontSize={24}>
                POD is first of its kind started in India enabling retail
                investors to private equity opportunities which was earlier
                limited to HNIs & VCs.
              </Typography>
              <Typography
                color="rgba(0, 0, 0, 0.87)"
                fontSize={30}
                fontWeight={700}
                mt={4}
              >
                Uday Kumar BS
              </Typography>
              <Typography
                color="rgba(0, 0, 0, 0.87)"
                fontSize={16}
                maxWidth={213}
              >
                Angel Investor & Trader Ex - London Stock Exchange Deutche Bank
              </Typography>
              <Box
                display="flex"
                justifyContent="flex-start"
                mt={2}
                sx={{
                  '& img': {
                    mr: 4,
                  },
                }}
                alignItems="center"
              >
                <img src={london} alt="london logo" />
                <img src={deutsche} alt="deutsche logo" />
              </Box>
            </Grid>
          </Grid>
          <Box
            width="100%"
            sx={{
              '& img': {
                width: '100%',
              },
            }}
            position="absolute"
            left={0}
            bottom={0}
          >
            <img src={shortCurlyWave} alt="illustration" />
            <Box
              width="24%"
              position="absolute"
              bottom="36%"
              left="11.5%"
              sx={{ '& img': { width: '100%' } }}
            >
              <img src={uday} alt="illustration" />
            </Box>
          </Box>
        </Container>
      </Box>
      <Box
        position="absolute"
        bottom={249}
        height={65}
        sx={{ '& img': { height: '100%', width: '900px', objectFit: 'fill' } }}
        width={1000}
        zIndex={5}
      >
        <img src={waveStraightShort} alt="wave illustration" />
      </Box>
      <Box
        position="absolute"
        bottom={0}
        height={59}
        sx={{
          '& img': { height: '100%', width: '900px', objectFit: 'fill' },
        }}
        width={1000}
        right={-500}
        zIndex={5}
      >
        <img src={waveStraightShort} alt="wave illustration" />
      </Box>
    </Box>
  );
};
