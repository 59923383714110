import {
  Box,
  Container,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { WhyPodCard } from './WhyPodCard';
import whyPodIcon from 'assets/images/whyPodIcon.svg';
import safeguard from 'assets/images/safeguard.svg';
import transparent from 'assets/images/transparent.svg';
import qualityStartups from 'assets/images/qualityStartups.svg';
import investorFriendly from 'assets/images/investorFriendly.svg';

export const WhyPod = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      component="section"
      bgcolor="#F8FBF5"
      mt="29px"
      sx={{
        pt: {
          xs: 0,
          md: 8,
        },
      }}
    >
      <Container maxWidth="lg" sx={{ bgcolor: '#E6F0F1' }}>
        <Box display="flex" alignItems="center">
          <img src={whyPodIcon} alt="why pod icon" />
          <Box flexGrow={1}>
            <Typography
              fontWeight={600}
              fontSize={24}
              color="#FF6446"
              letterSpacing="0.01em"
              mb="10px"
              mt="40px"
            >
              Startup Investment Made Easy With POD
            </Typography>
            <Typography
              fontWeight={900}
              fontSize={40}
              color="#004643"
              mb="19px"
            >
              Why POD
            </Typography>
          </Box>
        </Box>
        {/* <Grid container sx={{ bgcolor: '#F8FBF5' }}>
          <Grid item md={6}>
            <WhyPodCard
              title="Investor Friendly"
              content="asfsdfsdf"
              icon={investorFriendly}
            />
          </Grid>
          <Grid item md={6}>
            ssdfsasdasdsd
          </Grid>
        </Grid> */}
      </Container>
      <Container
        sx={{
          mt: 8,
          px: {
            md: '0 !important',
          },
        }}
      >
        <Grid container rowSpacing={matches ? 2 : 8} pb={8}>
          <Grid item md={6}>
            <WhyPodCard
              title="Single Click Investment "
              content={
                <Typography
                  color="rgba(0, 0, 0, 0.6)"
                  sx={{
                    '& span': {
                      color: '#E65D45',
                    },
                  }}
                >
                  {/* POD is an investor-friendly platform, which{' '}
                  <span>levies no commission</span>
                  or <span>hidden charges</span> from investors. */}
                  Invest <span>instantly</span> in promising Indian startups.
                </Typography>
              }
              icon={investorFriendly}
            />
          </Grid>
          <Grid item md={6}>
            <WhyPodCard
              title="Handpicked Startups"
              content={
                <Typography
                  color="rgba(0, 0, 0, 0.6)"
                  sx={{
                    '& span': {
                      color: '#E65D45',
                    },
                  }}
                >
                  Fully vetted startups across different sectors.
                </Typography>
              }
              icon={transparent}
            />
          </Grid>
          <Grid item md={6}>
            <WhyPodCard
              title="Portfolio Diversification"
              content={
                <Typography
                  color="rgba(0, 0, 0, 0.6)"
                  sx={{
                    '& span': {
                      color: '#E65D45',
                    },
                  }}
                >
                  With micro ticket size, invest in{' '}
                  <span>multiple startups.</span>
                </Typography>
              }
              icon={qualityStartups}
            />
          </Grid>
          <Grid item md={6}>
            <WhyPodCard
              title="Smart Dashboard"
              content={
                <Typography
                  color="rgba(0, 0, 0, 0.6)"
                  sx={{
                    '& span': {
                      color: '#E65D45',
                    },
                  }}
                >
                  Track and review the performance of your investment in{' '}
                  <span>real time.</span>
                </Typography>
              }
              icon={safeguard}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
