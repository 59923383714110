import { Container, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import joinNowBg from 'assets/images/joinNowBg.svg';
import joinNowBgMobile from 'assets/images/joinNowBgMobile.svg';
import { BigButton } from '../common/Buttons/BigButton';
import chevronRightWhite from 'assets/images/chevronRightWhite.svg';
import { useUser } from 'context/user.context';
import Link from 'components/Link';

export const JoinNow = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const [{ user }] = useUser();
  return (
    <Box bgcolor="#F6FBFB">
      <Container disableGutters>
        <Box
          display="flex"
          justifyContent="center"
          position="relative"
          sx={{ '& .invest-now-bg': { width: '100%' } }}
        >
          <img
            src={matches ? joinNowBgMobile : joinNowBg}
            className="invest-now-bg"
            alt="background image"
          />
          {/* <Link href={user ? '/startups' : '/register'}> */}
          <BigButton
            sx={{
              zIndex: 10,
              position: 'absolute',
              top: '50%',
              transform: 'translate(0, -50%)',
            }}
          >
            <Link noLinkStyle href={user ? '/startups' : '/register'}>
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  '& img': {
                    height: {
                      xs: 12,
                    },
                  },
                }}
              >
                <Typography
                  fontWeight={700}
                  fontSize={24}
                  mr="18px"
                  sx={{
                    fontSize: {
                      xs: 16,
                      md: 24,
                    },
                  }}
                >
                  Become an Investor
                </Typography>
                <img src={chevronRightWhite} alt="right arrow icon" />
              </Box>
            </Link>
          </BigButton>
        </Box>
      </Container>
    </Box>
  );
};
