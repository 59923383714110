import { MetaTags } from 'components/common';
import InvestPage from 'components/v2/InvestPage';
import RaiseFundPage from 'components/v2/raiseFundPage';
import { useUser } from 'context/user.context';
import type { NextPage } from 'next';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

const Home: NextPage = () => {
  const [{ user, isLoading }] = useUser();

  return (
    <>
      <MetaTags
        title={
          user?.role_id === 2
            ? 'Raise funds for your startup'
            : 'Invest in Indian Startups'
        }
        description={
          user?.role_id === 2
            ? 'Raise seed funding for your startup with POD. Register Now!'
            : 'Invest in promising early-stage Indian startups. Become an investor now!'
        }
        imageUrl={`${process.env.NEXT_PUBLIC_FRONTEND_URL}pod-logo.png`}
        url={process.env.NEXT_PUBLIC_FRONTEND_URL!}
        keywords="Invest in startups"
      />
      {user?.role_id == 2 ? <RaiseFundPage /> : <InvestPage />}
    </>
  );
};

export default Home;
