import { Box, Typography } from '@mui/material';
import { ReactNode } from 'react';

interface WhyPodCardProps {
  icon: string;
  title: string;
  content: ReactNode;
}

const OrangeBullet = () => (
  <Box height={24} width={3} borderRadius={20} bgcolor="#FF6446" />
);

export const WhyPodCard = ({ icon, title, content }: WhyPodCardProps) => {
  const margin = 2;
  return (
    <Box>
      <Box ml={margin}>
        <img src={icon} alt="title" />
      </Box>
      <Box display="flex" mt={2}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <OrangeBullet />
        </Box>
        <Box ml={margin}>
          <Typography
            color="#004643"
            fontWeight={600}
            fontSize={24}
            component="h3"
          >
            {title}
          </Typography>
        </Box>
      </Box>
      <Box mt={1.5} ml={margin} mr="100px">
        {content}
      </Box>
    </Box>
  );
};
