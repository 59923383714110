import { Box, useMediaQuery, useTheme } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import { Testimonial } from '.';
import { Arrow } from '../../common/Arrow';
import { InvestorOne } from './InvestorOne';
import { TestimonialHeader } from './TestimonialHeader';

export const TestimonialCarousel = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Box pb={8}>
      <TestimonialHeader />
      <Carousel
        preventMovementUntilSwipeScrollTolerance
        swipeScrollTolerance={50}
        infiniteLoop
        // showArrows={true}
        showStatus={false}
        showThumbs={false}
        renderArrowNext={(onClickHandler) =>
          matches ? null : (
            <Box
              position="absolute"
              top="50%"
              sx={{ transform: 'translate(0, -50%)' }}
              right={50}
              zIndex={20}
            >
              <Arrow onClick={onClickHandler} />
            </Box>
          )
        }
        renderArrowPrev={(onClickHandler) =>
          matches ? null : (
            <Box
              position="absolute"
              top="50%"
              sx={{ transform: 'translate(0, -50%)' }}
              left={50}
              zIndex={20}
            >
              <Arrow onClick={onClickHandler} direction="left" />
            </Box>
          )
        }
        renderIndicator={(onClickHandler, isSelected, index, label) => (
          <Box
            position="relative"
            // component="li"
            height={10}
            width={10}
            bgcolor={isSelected ? '#008B5E' : '#D9D9D9'}
            display="inline-block"
            mx={1}
            sx={{ cursor: 'pointer' }}
            onClick={onClickHandler}
            borderRadius={10}
            zIndex={2000}
          />
        )}
      >
        <Testimonial />
        {/* <Testimonial /> */}
        <InvestorOne />
      </Carousel>
      {/* <Arrow />
      <Arrow direction="left" /> */}
    </Box>
  );
};
